import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Testimonial1 from "../sections/testimonials/Testimonial1"

const TestimonialPage2 = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          hasBottomSubscription: true,
          bottomClassName: "bg-primary-25 ",
          className: "!bg-primary-50",
        }}
      >
        <GatsbySeo
          title={`Kloft - Testimonial 02`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="Testimonial 02"
          text="Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information."
        />
        <Testimonial1 className="!bg-white" />
      </PageWrapper>
    </>
  )
}

export default TestimonialPage2
